import JobsApi    from '@WS/api/job';
import { set, reset, set_if_not_null } from '../mutators';
import {
    SET_REQUEST_SUMMARIES,
    REMOVE_REQUEST_SUMMARY,
    SET_REQUESTS,
    SET_REQUESTS_LOADING,
    SET_REQUESTS_ERROR,
    SET_SELECTED_WHOLEGOOD,
    SET_SELECTED_WHOLEGOOD_OBJ,
    SET_SELECTED_CUSTOMER,
    SET_SELECTED_REQUEST,
    REMOVE_REQUEST,
    SET_PRIORITIES,
    SET_PRIORITIES_LOADING,
    SET_PRIORITIES_ERROR,
    RESET
} from '../mutation-types';

const initialState = () => ({	
    requestSummaries: {
        value   : [],
        loading : false,
        error   : null,
    },
    selection: {
        wholegood: null,
        wholegoodObj: {},
        customer: null,
        customerObj: {},
        requests: [],
        request: {}
    },
    priorities: {
        value: [],
        loading: false,
        error: null
    }
});

const getters = {
  
};

const mutations = {
    // Set Job Requests
    [SET_REQUEST_SUMMARIES]   : set('requestSummaries.value',          'requestSummaries'),
    REMOVE_REQUEST_SUMMARY(state, summary){
        let index = _.findIndex(state.requestSummaries.value, (sum) => sum.Customer.AccountNo == summary.Customer.AccountNo);
        if (-1 !== index){
            state.requestSummaries.value.splice(index, 1);
        }
    },
    [SET_REQUESTS]            : set('selection.requests',              'requests'),
    [SET_REQUESTS_LOADING]    : set('requestSummaries.loading',        'loading'),
    [SET_REQUESTS_ERROR]      : set('requestSummaries.error',          'error'),

    [SET_SELECTED_REQUEST]    : set('selection.request',               'request'),    
    REMOVE_REQUEST(state, request){
        let index = _.findIndex(state.selection.requests, (r) => r.Id == request.Id);
        if (-1 !== index){
            state.selection.requests.splice(index, 1);
        }
    },
    [SET_SELECTED_WHOLEGOOD]  : set_if_not_null('selection.wholegood', 'wholegood'),
    SET_SELECTED_CUSTOMER(state, customer) {
        state.selection.customer    = _.get(customer, "AccountNo");
        state.selection.customerObj = customer;
    },
    SET_SELECTED_WHOLEGOOD_OBJ(state, wholegood) {
        state.selection.wholegood    = _.get(wholegood, 'Id');
        state.selection.wholegoodObj = wholegood;            
    },

    // Set Priorities
    [SET_PRIORITIES]          : set('priorities.value',    'priorities'),
    [SET_PRIORITIES_LOADING]  : set('priorities.loading',  'loading'),
    [SET_PRIORITIES_ERROR]    : set('priorities.error',    'error'),
   
    // Reset state.
    [RESET]: (state) => reset(state, initialState())
};

const actions = {
    /**
     * Clears all selected items.
     * @param {Object} commit - The mutator.
     */ 
    clearSelection({commit}){
        commit(SET_REQUEST_SUMMARIES, { requestSummaries: [] });
        commit(SET_REQUESTS, { requests: [] });

        commit(SET_SELECTED_WHOLEGOOD_OBJ, {} );
        commit(SET_SELECTED_CUSTOMER,  {} );
        commit(SET_SELECTED_REQUEST, {request: {} });
    },
    /**
     * Sends a request to get a list of priorities.
     * 
     */
    async fetchJobPriorities({state, commit}){
        commit(SET_PRIORITIES_LOADING, {loading: true});
        try {
            const response = await JobsApi.getJobPriority();
            const priorities = response.data.data;
            
            commit(SET_PRIORITIES, {priorities: priorities});
        }
        catch (error) {
            commit(SET_PRIORITIES_ERROR, {error: error});
        }
        finally {
            commit(SET_PRIORITIES_LOADING, {loading: false});
        }
    },

    /**
     * Request the list of customers and wholegoods with outstanding
     * job requests.
     * 
     * If nothing is currently selected, select the first wholegood and customer.
     */
    async fetchJobRequestSummaries({ state, commit },request) {
        try {
            if (0 === state.requestSummaries.value.length) {
                commit(SET_REQUESTS_LOADING, { loading: true });
            }
            const response  = await JobsApi.getJobRequestSummaries(request);
            const requestSummaries = response.data.data;

            commit(SET_REQUEST_SUMMARIES, { requestSummaries: requestSummaries });
            
            // If we don't have anything selected initalise to the first thing returned.
            if (!state.selection.customer && requestSummaries && 1 <= requestSummaries.length) {
                const summary   = _.first(requestSummaries);
                const customer  = _.get(summary, 'Customer');
                const wholegoodSummary = _.first( _.get(summary, 'WholegoodRequestSummaries') );

                commit(SET_SELECTED_WHOLEGOOD_OBJ, wholegoodSummary.Wholegood);
                commit(SET_SELECTED_CUSTOMER,  customer );
            }
        }
        catch (error) {
            commit(RESET);
            commit(SET_REQUESTS_ERROR, { error: error });
        }
        finally {
            commit(SET_REQUESTS_LOADING, { loading: false });
        }
    },

    
    /**
     * Request the list of job requests
     * 
     * If nothing is currently selected, select the first wholegood and customer.
     */
    async fetchJobRequests({ state, commit }) {
        try {
            const response  = await JobsApi.getJobRequestsForWholegood(state.selection.wholegood);
            const requests = response.data.data;
            
            commit(SET_REQUESTS, { requests: requests });

            // If we don't have anything selected initalise to the first thing returned.
            if (!state.selection.request && requests && 1 <= requests.length) {
                let request = requests[0] || null;
                commit(SET_SELECTED_REQUEST, { request: request } );
            }
        }
        catch (error) {
            commit(RESET);
            commit(SET_REQUESTS_ERROR, { error: error });
        }
        finally {
            commit(SET_REQUESTS_LOADING, { loading: false });
        }
    },

    removeRequest({ state, commit }, request) {
        const customerId  = request.CustomerId;
        const wholegoodId = request.WholegoodId;

        commit(REMOVE_REQUEST, request);
        commit(SET_SELECTED_REQUEST, {request: {} });
        
        // Select next wholegood.
        if (0 === state.selection.requests.length){
            selectNextWholegood(state, commit, customerId, wholegoodId);
        }
    }
};

/**
 * 
 * @param {Object} state 
 * @param {Object} commit 
 * @param {string} customerId 
 * @param {string} wholegoodId 
 */
function selectNextWholegood(state, commit, customerId, wholegoodId) {
    // Find the summary based on the customer in the supplied request.
    let summary = _.find(state.requestSummaries.value, (s)=> {           
        return customerId === s.Customer.AccountNo;
    });
    // get the index number of this summary
    let indexOfSumnmary = _.indexOf(state.requestSummaries.value, summary);

    // set the selectionIndex to be the same as the current selection
    let newSummaryIndex = indexOfSumnmary;

    // is this the last summary? in which case we want to stay on the last item
    if(newSummaryIndex == state.requestSummaries.value.length - 1){
        newSummaryIndex--;
    }

    if (summary) {
        var wholegoodSummary=_.find(summary.WholegoodRequestSummaries, (w) => {
            return wholegoodId === w.Wholegood.Id;
        });
        // Remove. The wholegood matching the id in the request
        _.remove(summary.WholegoodRequestSummaries, wholegoodSummary)
        
        // If there are no wholegoods left for this customer.
        if (_.isEmpty(summary.WholegoodRequestSummaries)) {

            commit(REMOVE_REQUEST_SUMMARY, summary);

            // Select the next customer and first wholegood for that customer. 
            const nextSummary = state.requestSummaries.value[newSummaryIndex];
            const customer = _.get(nextSummary, 'Customer');
            const wholegoodRequestSummary = _.first( _.get(nextSummary, 'WholegoodRequestSummaries') );

            if (customer && wholegoodRequestSummary.Wholegood){
                commit(SET_SELECTED_WHOLEGOOD_OBJ, wholegoodRequestSummary.Wholegood);
                commit(SET_SELECTED_CUSTOMER,  customer );
            }
        }
        // If this customer still has wholegoods, move to the next one.
        else {
            let nextWholegoodRequestSummary = _.first(summary.WholegoodRequestSummaries);
            commit(SET_SELECTED_WHOLEGOOD_OBJ, nextWholegoodRequestSummary.Wholegood);                    
        }
    }
}

export default {
    namespaced  : true,
	state       : initialState(),
	getters,
	mutations,
	actions
}
