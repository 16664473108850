<template>
<div
    v-if="isLoggedIn"
    class="app d-flex flex-row h-100"
    @keydown.[="toggleSidebarOpen"
    tabindex="-1"
>
    <!-- Settings Panel -->
    <settings-menu />

    <!-- User Account Popup Menu -->
    <account-menu />

    <!-- Quick Access Bar -->
    <quickbar />

    <!-- Collapsible Side Bar -->
    <sidebar ref="sidebar" />

    <div
        ref="mainContainer"
        class="main-container d-flex flex-column"
    >
        <!-- Main Content -->
        <main class="container-fluid flex-grow-1 px-4 py-2">
            <router-view />
        </main>

        <actionbar />
    </div>
</div>
<login v-else></login>
</template>

<script>
import {
    mapState,
    mapGetters,
    mapMutations,
    mapActions,
}                       from 'vuex';
import * as mutation    from '@/store/mutation-types';
import Login            from "@/views/Login";
import SettingsMenu     from '@/views/SettingsMenu';
import AccountMenu      from '@/views/AccountMenu';
import Quickbar, {
    QUICKBAR_WIDTH,
}                       from '@/views/Quickbar';
import Sidebar, {
    SIDEBAR_COLLAPSED_WIDTH,
    SIDEBAR_EXPANDED_WIDTH,
}                       from '@/views/Sidebar';
import Actionbar        from '@/views/Actionbar';
import {SET_IS_ROLE_REQUIRED} from "@/store/mutation-types";

export default {
    components: {
        Login,
        SettingsMenu,
        AccountMenu,
        Quickbar,
        Sidebar,
        Actionbar,
    },

    data() {
        return {
            animations  : {
                sidebar         : {
                    keyframes   : [{
                        minWidth: SIDEBAR_EXPANDED_WIDTH,
                    }, {
                        minWidth: SIDEBAR_COLLAPSED_WIDTH,
                    }],
                    player      : null,
                },
                mainContainer   : {
                    keyframes   : [{
                        maxWidth: `calc(100% - (${QUICKBAR_WIDTH} + ${SIDEBAR_EXPANDED_WIDTH}))`,
                    }, {
                        maxWidth: `calc(100% - (${QUICKBAR_WIDTH} + ${SIDEBAR_COLLAPSED_WIDTH}))`,
                    }],
                    player      : null,
                },
                options         : {
                    duration    : 250,
                    easing      : 'cubic-bezier(0.445, 0.050, 0.550, 0.950)',
                    fill        : 'both',
                    iterations  : 1,
                },
            },
        };
    },

    created() {
        window.addEventListener('keydown', this.kode());
    },

    computed: {
        ...mapState('core', {
            isSidebarOpen   : state => state.sidebar.open,
        }),

        ...mapGetters('auth', {
            isLoggedIn      : 'hasToken',
            userRoles       : 'getUserRoles'
        }),
    },

    watch: {
        isLoggedIn: {
            immediate: true,
            handler(newValue, oldValue) {
                this.updateAnimations();
                if (newValue) {

                    this.checkRoles();

                    let moduleName = this.$router.currentRoute.fullPath.split('/')[1];
                    let queryParam = this.$router.currentRoute.query.return;
                    if (queryParam) {
                        window.history.replaceState({}, document.title, `/#${this.$router.currentRoute.path}`);
                        window.location.href = `${process.env.VUE_APP_SCHEDULER_URL}/#/${moduleName}/${queryParam}`;
                    }
                }
            },
        },

        isSidebarOpen(newValue, oldValue) {
            this.startAnimations();
        },
    },

    methods: {
        ...mapMutations('core', {
            toggleSidebarOpen   : mutation.TOGGLE_SIDEBAR_MENU,
        }),

        ...mapMutations('system', {
            setIsRoleRequired   : SET_IS_ROLE_REQUIRED
        }),

        ...mapActions('system', {
            fetchCompanies  : 'fetchCompanies',
            fetchDepots     : 'fetchDepots',
        }),

        checkRoles() {
            if (this.$router.currentRoute.meta.roles
                && this.$router.currentRoute.meta.roles.length) {
                let isRolesExist = this.$router.currentRoute.meta.roles.every(value => this.userRoles.includes(value));
                if (isRolesExist) {
                    this.setIsRoleRequired({isRoleRequired: false})
                } else {
                    this.setIsRoleRequired({isRoleRequired: true})
                }
            }
        },
        createAnimations() {
            const sidebar       = this.$refs.sidebar.$el;
            const mainContainer = this.$refs.mainContainer;

            this.animations.sidebar.player = this.createAnimation(
                sidebar,
                this.animations.sidebar.keyframes,
                this.animations.options
            );
            this.animations.mainContainer.player = this.createAnimation(
                mainContainer,
                this.animations.mainContainer.keyframes,
                this.animations.options
            );
        },

        createAnimation(element, keyframes, options) {
            const player = element.animate(keyframes, options);

            player.pause();
            player.onfinish = () => this.animationFinished(player);

            return player;
        },

        clearAnimations() {
            this.animations.sidebar.player          = null;
            this.animations.mainContainer.player    = null;
        },

        startAnimations() {
            this.startAnimation(this.animations.sidebar.player);
            this.startAnimation(this.animations.mainContainer.player);
        },

        startAnimation(player) {
            // Fail fast if there is no animation player.
            if (!player) {
                return;
            }

            if ('running' === player.playState) {
                player.pause();
                player.reverse();
            }
            else {
                player.play();
            }
        },

        animationFinished(player) {
            player.reverse();
            player.pause();
        },

        updateAnimations() {
            let action = this.clearAnimations;
            if (true === this.isLoggedIn) {
                action = this.createAnimations;
            }

            this.$nextTick()
                .then(action);
        },

        kode() {
            const me    = this;
            const kkode = '38,38,40,40,37,39,37,39,66,65';
            let   kkeys = [];
            return event => {
                kkeys.push(event.keyCode);
                if (kkeys.length > 10){
                    kkeys.shift();
                }

                if (me.isLoggedIn && kkeys.toString().indexOf(kkode) >= 0) {
                    kkeys = [];
                    console.warn("Code accepted.");
                }
            };
        }
    },
}
</script>

<style lang="less">
@import 'assets/css/colours.less';
@import 'assets/css/imports.less';

html, body {
    height: 100%;
}

main {
    overflow-y: auto;
    overflow-x: auto;
}

.app {
    font-family: 'Ubuntu', 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:focus {
        outline: none;
    }
}

.main-container {
    max-width: calc(100% - (@quickbar-width + @sidebar-expanded-width));
    width: 100%;
}

.container-fluid {
    height: inherit;
}
</style>
