const DEALER_GENERATED          = 0;
const JOHN_DEEERE               = 1;
const JCB                       = 2;

const ManufacturerCodes = {
    DEALER_GENERATED,
    JOHN_DEEERE,
    JCB
};

const ManufacturerStrings = {
    0 : 'Dealer Generated',
    1 : 'John Deere',
    2 : 'JCB'
};

const NotifyManufacturerOnCancel = {
    0 : true,
    1 : true,
    2 : false
}
       
const JobRequestManufacturers = {
    methods: {
        getManufacturerForId(value) {
            let manufacturerName = ManufacturerStrings[value];
            if(undefined === manufacturerName){
                manufacturerName = "Unknown";
            }
            return manufacturerName;
        },
        getNotifyManufacturerOnCancelRequest(value) {
            let preference = NotifyManufacturerOnCancel[value];
            if(undefined === preference) {
                preference = false;
            }
            return preference;
        }
    }
}
export default JobRequestManufacturers;