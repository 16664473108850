<template>
    <div class="container-fluid h-100">
        <!-- Errors -->
        <div class="alert alert-danger" role="alert" v-if="error">
            <h4 class="alert-heading">Error</h4>
            {{ error }}
        </div>

         <!-- Customer and Wholegood Cards -->
        <div class="row mb-4">
            <div class="col-6"> 
                <div class="card mb-1 bg-light h-100  shadow-sm">
                    <div class="card-header  bg-secondary text-light">
                        <i class="fas fa-fw  fa-lg fa-address-book"></i> {{ customer.AccountNo }} - {{ customer.Name }}
                    </div>

                    <div class="card-body">
                        <label class="info-card">Phone:</label> {{ customer.Telephone || "-" }} <br />
                        <label class="info-card">Phone (Alt):</label> {{ customer.TelephoneAlt || "-" }} <br />
                        <label class="info-card">Email:</label> <a :href="`mailto:${customer.Email}`" target="_blank">{{ customer.Email || "-" }}</a> <br />
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card mb-1 bg-light h-100 shadow-sm">
                    <div class="card-header bg-secondary text-light d-flex">
                        <span class="flex-grow-1">
                            <i class="fas fa-fw  fa-lg fa-tractor"></i> 
                            {{ wholegood.Id }} - {{ wholegood.Model }}
                        </span>
                        <request-info-modal class="align-self-end" :customer="customer" :wholegood="wholegood" :latestRequest="latestRequest"/>                            
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <label class="info-card">WG Clock Hours:</label>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <span :id="`wholegood-request-clock${wholegood.Id}`">
                                    {{ latestRequest.ClockHours }} (Current)
                                </span>
                                <b-popover :target="`wholegood-request-clock${wholegood.Id}`"
                                    triggers="hover"
                                    :content="getRequestClockHoursTime">
                                </b-popover>                   
                            </div>
                            <div class="col-md-4 col-sm-6">
                                {{ goldClockHours }} (Gold)
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12"><label class="info-card">Warranty:</label></div>
                            <div class="col-md-4 col-sm-6">
                                {{ wholegood.WarrantyEndDate | formatDate(DateFormat.DATE_ONLY) }}
                            </div>
                            <div class="col-md-4 col-sm-6">
                                {{ wholegood.WarrantyEndHours | formatClockHours }} 
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <label class="info-card">Warranty (Extended):</label> 
                            </div>
                            <div class="col-md-4 col-sm-6">
                                {{ wholegood.WarrantyExtendedDate | formatDate(DateFormat.DATE_ONLY) }}
                            </div>
                            <div class="col-md-4 col-sm-6">
                                {{ wholegood.WarrantyExtendedHours | formatClockHours }}                                 
                            </div>      
                        </div>                  
                    </div>
                </div>
            </div>
        </div>

        <div class="row h-75 mh-75">
            <div class="col-12 h-100">
                <div class="card h-100 bg-light shadow-sm">
                    <div class="card-header bg-secondary text-light">
                        <i class="fas fa-fw fa-lg fa-wrench"></i> Incoming Requests
                    </div>

                    <div class="card-body pt-0 pb-0 pl-1 pr-1 overflow-auto" id="requestTableContainer">
                        
                        <div v-if="!selected.requests || 0 == selected.requests.length"
                            class="d-flex h-100 justify-content-center align-items-center">
                            <div role="alert" 
                                class="alert alert-secondary text-muted shadow-sm">
                                No job requests remaining for this wholegood.
                            </div>
                        </div>

                        <ib-table v-else
                            id="requestTable"
                            :columns="headings"
                            :items="selected.requests"
                            :loadingMessage="loadingMessage"
                            :itemsPerPage="9999"
                            v-model="request"
                        >
                            <template slot="RequestTypeId"
                                    slot-scope="data">                                
                                <font-awesome-icon
                                    :id="`request-${data.item.Id}`"
                                    :icon="buildTypeIcons(data.item)"
                                    :class="buildTypeClass(data.item)"
                                    size="lg"
                                    fixed-width
                                />
                                <b-popover :target="`request-${data.item.Id}`"
                                    triggers="hover"
                                    :content="getPriorityForId(data.item.Priority)">
                                </b-popover>
                            </template>

                            <template slot="ManufacturerId"
                                slot-scope="data">
                                    {{ getManufacturerForId(data.item.ManufacturerId)  }}
                            </template>

                            <template slot="RequestReceivedOn"
                                slot-scope="data">
                                    {{ data.item.RequestReceivedOn | formatDate() }}
                            </template>

                            <template slot="RequestedFor"
                                slot-scope="data">
                                    {{ data.item.RequestedFor | formatDate() }}
                            </template>

                            <template slot="Priority"
                                slot-scope="data">
                                    {{ getPriorityForId(data.item.Priority) }}
                            </template>
                        </ib-table> 
                    </div>

                    <div class="card-footer text-right">
                        <request-reject-notify v-model="showReject" 
                        :manufacturer="getManufacturerForId(this.selected.request.ManufacturerId)"
                        :supportsNotify="manufacturerSupportsCancelNotification"
                        @hideReject="hideReject"
                        @deleteJobRequest="deleteJobRequest"/>
                        <button class="btn" 
                                :class="{
                                    'btn-outline-danger ib-cursor-denied' : !haveRequest,
                                    'btn-danger'              : haveRequest
                                }"
                                :disabled="false == haveRequest || true == processingRequest"
                                @click="showRejectNotify">
                            <i class="fas fa-fw fa-trash"></i>Reject
                        </button>
                        &nbsp;&nbsp;                        
                        <button class="btn" 
                                :class="{
                                        'btn-outline-success ib-cursor-denied' : !haveRequest,
                                        'btn-success'              : haveRequest
                                    }"
                               :disabled="false == haveRequest"
                               @click="showJobCreate">
                            <i class="fas fa-fw fa-notes-medical"></i>Create
                        </button>
                    </div>
                </div>
            </div>           
        </div>                            
    </div>
</template>

<script>
import WholegoodsApi                from '@WS/api/wholegood';
import JobApi                       from '@WS/api/job';
import ContactApi                   from '@WS/api/contact';
import JobRequestPriorities         from '@WS/common/job-request-priorities';
import JobRequestManufacturers      from '@WS/common/job-request-manufacturers';
import IbTable                      from '@/components/table/IbTable';

import { ToastPlugin }              from 'bootstrap-vue'
import {
    mapState,
    mapGetters,
    mapMutations,
    mapActions
}                               from 'vuex';
import DateMixin, {
    DateFormat
}                               from '@/mixins/DateMixin';

import IbDatePicker             from '@/components/form/IbDatePicker';
import IbSelectEntry            from '@/components/form/IbSelectEntry';
import IbCheckbox               from '@/components/form/IbCheckbox';
import DepotList                from '@WS/components/DepotList';
import IbDateTimePicker         from '@/components/form/IbDateTimePicker';
import IbInvoiceTypeLookup      from '@WS/components/lookups/IbInvoiceTypeLookup';
import IbTimeInput              from '@/components/form/IbTimeInput';
import InvoiceTypeApi           from '@WS/api/invoice-type';
import IbCustomerLookup         from '@WS/components/lookups/IbCustomerLookup';
import RequestInfoModal         from '@WS/components/job-requests/RequestInfoModal';
import RequestRejectNotify      from '@WS/components/job-requests/RequestRejectNotify';

import {
    SET_SELECTED_REQUEST
}                               from '@/store/mutation-types';


const headings = [
    {
        heading     : 'Type',
        property    : 'RequestTypeId',
        class       : 'typeCol',
        sortable    : true,
    },
    {
        heading     : 'Priority',
        property    : 'Priority',
        class       : 'priorityCol',
        sortable    : true
    },
    {
        heading     : 'Manufacturer',
        property    : 'ManufacturerId',
        class       : 'manufacturerCol',
        sortable    : true
    },
    {
        heading     : 'Received',
        property    : 'RequestReceivedOn',
        class       : 'recievedCol',
        sortable    : true
    },
    {
        heading     : 'Request Title',
        property    : 'RequestTitle',
        class       : 'descriptionCol',
        sortable    : true
    }    
];


export default {
    name: 'RequestTab',

    mixins: [
        DateMixin, 
        JobRequestManufacturers,
        JobRequestPriorities
    ],
 
    components: {
        IbTable,
        RequestInfoModal,
        RequestRejectNotify
    },

    data() {
        return {
            headings            : headings,
            loading             : false,
            error               : "",            
            customer            : {},
            wholegood           : {},
            loadingMessage      : "Loading requests...",
            processingRequest   : false,
            latestRequest       : { ClockHours: 0, RequestReceivedO: 0 },
            showReject          : false,
            manufacturerSupportsCancelNotification : false
        }
    },   

    mounted() {
        if(null !== this.selected.request){
            this.request  = this.selected.request;
        }
    },
    watch: {      
        'selected.requests': {
            immediate: true,
            handler: function(newRequest){
                const latestRequest = _.max(this.selected.requests, request => request.ClockHours); 
                if(false === _.isNil(latestRequest)){
                    this.latestRequest = latestRequest;
                }
            }
        }, 
        'selected.customerObj': {
            immediate: true,
            handler: function(newCust){
                this.customer = newCust;
            }
        },

        'selected.wholegoodObj': {
            immediate: true,
            handler  : function(newWgd){
                this.wholegood = newWgd;
                this.fetchJobRequests();
            }
        }
    },

    computed: {
        ...mapState('jobrequest', {
            selected : 'selection'
        }),

        haveRequest(){
            let selected = false;
            if (this.request && !_.isEmpty(this.request)){
                selected = true;
            }
            return selected;  
        },

        request: {
            get: function(){
                return this.selected.request;
            },
            set: function(newRequest){
                this.setRequest({ request: newRequest} )
            }
        },
        getRequestClockHoursTime(){
            const me = this;
            const maxClockHours = _.max(me.selected.requests, request => request.ClockHours);
            let result = '0';

            if(null !== maxClockHours && undefined !== maxClockHours){
                result = `Recorded on: ${me.getDateString(maxClockHours.LastModifiedOn, this.DateFormat.DATE_ONLY)}`;
            }

            return result;
        },
        goldClockHours(){
            const me = this;
            let result = '0';

            if(false === _.isNil(me.wholegood)){
                let serviceHours = me.wholegood.ClockHoursAtLastService;
                let clockHours = me.wholegood.ClockHours;
                
                if (false == _.isNumber(serviceHours)){
                    serviceHours = 0;
                }

                if (false == _.isNumber(clockHours)){
                    clockHours = 0;
                }

                result = `${Math.max(serviceHours, clockHours)}`;
            }
            
            return result;
        }
    },
  
    methods: {
        /**
         *  Map in state load functions.
         */ 
        ...mapActions('jobrequest', {
            fetchJobRequests:         'fetchJobRequests',
            removeRequest:            'removeRequest'
        }),
        /**  
         * Map in 'setters' for state items.
         */
        ...mapMutations('jobrequest', {
            setRequest: SET_SELECTED_REQUEST
        }),

        showJobCreate(){
            this.$emit("create");
        },

        selectRequest(request){
            this.setRequest({request});
        },
        showRejectNotify(){
            const me         = this;
            const request    = me.request;
            this.manufacturerSupportsCancelNotification = this.getNotifyManufacturerOnCancelRequest(request.ManufacturerId);
            this.showReject = true;
        },
        hideReject(){
            this.showReject = false;
        },
        /**
         * Reject creating the selected job request.
         * 
         */
        async deleteJobRequest(notify) {
            const me         = this;
            const request    = me.request;
            let toastStatus  = 'warning';
            let toastMessage = `Workshop Job Request rejected.`;

            if (me.processingRequest == true) {
                return;
            }

            try
            {
                await JobApi.deleteJob(request, notify)                            
                me.removeRequest(request);
                me.$emit('refreshFilteredRequestList', true);
            }
            catch(error) {
                toastStatus = "error";
                if (error.response){
                    toastMessage = `Failed to reject job request. ${error.response.data.message}`;
                }
                console.error(`Rejecting job request failed. ${toastMessage}`, error);
                me.error = `Failed to reject job request  ${request.Id} - ${toastMessage}.`;
            }
            finally{
                me.processingRequest = false;
                me.toast('b-toaster-top-center', toastStatus, toastMessage, true);
            }

        },

        /** 
         * Set approprite css classes for Type column icon and colour scheme. 
         */
        buildTypeIcons(request){           
            let icon = ['fas', 'tools'];

            switch(request.RequestTypeId) {
                case 1: // Service
                case 1002: // JD_Job
                    icon = ['fas', 'oil-can'];
                    break;
                case 2: // Fault
                case 1001: // JD_Alert
                default:                    
            }
            return icon;

        },

        buildTypeClass(request){
            let severity = "text-muted";
            switch(request.Priority) {
                case 4: // Critical
                case 1002: // JD Critical
                    severity = 'text-danger';
                    break;                
                case 3: // High
                case 1001: // JD_High
                case 1003: // JD_DTC
                    severity = 'ibc-text-high';
                    break;
                case 2: // Medium
                    severity = 'text-medium';
                    break;
                case 1: //Low                       
                default:
                    break;
            }

            return severity;
        },

        hasWarrantyDateExpired(date){
            let expired = false;
            if (_.isEmpty(date)){
                return expired;
            }
            const now   = new Date();
            const today = this.getDateObject(now.toISOString()).utc().startOf('day').toISOString();
            const warr  = this.getDateObject(date).utc().startOf('day').toISOString();
            expired = this.isDateBefore(warr,today, 'day');
            
            return expired;
        },

        /**
         * Returns true if the wholegood hours has exceeded the warranty hours.
         * If a non-numeric value is passed assumes it has not expired.
         */
        hasWarrantyHoursExpired(warrantyHours){            
            let   expired  = false;
            const wgdHours = Math.max(this.goldClockHours, this.latestRequest.ClockHours);

            if (false ==_.isNumber(warrantyHours) || false == _.isNumber(wgdHours)) {
                return expired;
            }
            // If either value is 0; then we can't determine if it's expired or not.
            if (0 === warrantyHours || 0 === wgdHours) {
                return expired;
            }

            if (warrantyHours <= wgdHours){
                expired = true;
            }

            return expired;
        },

        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Workshop Job Request`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        }
    },

    filters: {
        formatClockHours(hours){
            let output = "";
            if (!hours) return output;

            if (0 != hours){
                output = `${hours} hours`;
            }
            return output;
        }
    }
}
</script>


<style lang="less" scoped>
.ibc-text-high {
    color: #f79232;
}

.ib-cursor-denied {
    cursor: not-allowed;
}

/deep/ .typeCol {
    width: 90px !important;
}

/deep/ .priorityCol {
    width: 15% !important;
}

/deep/ .manufacturerCol {
    width: 15% !important;
}

/deep/ .recievedCol {
    width: 15% !important;
}

/deep/ .descriptionCol {
    width: auto;
}
</style>
