export const SET_JOB                         = 'SET_JOB';
export const SET_JOB_LOADING                 = 'SET_JOB_LOADING';
export const SET_JOB_ERROR                   = 'SET_JOB_ERROR';
export const SET_JOB_DATA_LOADING            = 'SET_JOB_DATA_LOADING';
export const SET_TASKS                       = 'SET_TASKS';
export const SET_TASKS_LOADING               = 'SET_TASKS_LOADING';
export const SET_TASKS_ERROR                 = 'SET_TASKS_ERROR';
export const SET_ACTIVITIES                  = 'SET_ACTIVITIES';
export const SET_ACTIVITIES_LOADING          = 'SET_ACTIVITIES_LOADING';
export const SET_ACTIVITIES_ERROR            = 'SET_ACTIVITIES_ERROR';
export const SET_COMPLETIONS                 = 'SET_COMPLETIONS';
export const SET_COMPLETIONS_LOADING         = 'SET_COMPLETIONS_LOADING';
export const SET_COMPLETIONS_ERROR           = 'SET_COMPLETIONS_ERROR';
export const SET_ACTIVE_JOBS                 = 'SET_ACTIVE_JOBS';
export const SET_ACTIVE_JOBS_LOADING         = 'SET_ACTIVE_JOBS_LOADING';
export const SET_ACTIVE_JOBS_ERROR           = 'SET_ACTIVE_JOBS_ERROR';
export const SET_LOGGED_JOBS                 = 'SET_LOGGED_JOBS';
export const SET_LOGGED_JOBS_LOADING         = 'SET_LOGGED_JOBS_LOADING';
export const SET_LOGGED_JOBS_ERROR           = 'SET_LOGGED_JOBS_ERROR';
export const SET_JOBS_KANBAN_SEARCH          = 'SET_JOBS_KANBAN_SEARCH';
export const SET_JOB_SUB_STATES              = 'SET_JOB_SUB_STATES';
export const SET_JOB_SUB_STATES_LOADING      = 'SET_JOB_SUB_STATES_LOADING';
export const SET_JOB_SUB_STATES_ERROR        = 'SET_JOB_SUB_STATES_ERROR';
export const SET_CHECKLISTS                  = 'SET_CHECKLISTS';
export const SET_MILEAGES                    = 'SET_MILEAGES';
export const SET_MILEAGES_LOADING            = 'SET_MILEAGES_LOADING';
export const SET_MILEAGES_ERROR              = 'SET_MILEAGES_ERROR';
export const SET_NOTES                       = 'SET_NOTES';
export const SET_NOTES_LOADING               = 'SET_NOTES_LOADING';
export const SET_NOTES_ERROR                 = 'SET_NOTES_ERROR';
export const SET_SERIAL_NUMBER               = 'SET_SERIAL_NUMBER';
export const SET_SERIAL_NUMBER_LOADING       = 'SET_SERIAL_NUMBER_LOADING';
export const SET_SERIAL_NUMBER_ERROR         = 'SET_SERIAL_NUMBER_ERROR';
export const SET_SIGNATURES                  = 'SET_SIGNATURES';
export const SET_SIGNATURES_LOADING          = 'SET_SIGNATURES_LOADING';
export const SET_SIGNATURES_ERROR            = 'SET_SIGNATURES_ERROR';
export const SET_CLOCK_HOURS                 = 'SET_CLOCK_HOURS';
export const SET_CLOCK_HOURS_LOADING         = 'SET_CLOCK_HOURS_LOADING';
export const SET_CLOCK_HOURS_ERROR           = 'SET_CLOCK_HOURS_ERROR';
export const SET_ENGINEERS                   = 'SET_ENGINEERS';
export const SET_ENGINEERS_LOADING           = 'SET_ENGINEERS_LOADING';
export const SET_ENGINEERS_ERROR             = 'SET_ENGINEERS_ERROR';
export const SET_CUSTOMER_WHOLEGOODS         = 'SET_CUSTOMER_WHOLEGOODS';
export const SET_CUSTOMER_WHOLEGOODS_LOADING = 'SET_CUSTOMER_WHOLEGOODS_LOADING';
export const SET_CUSTOMER_WHOLEGOODS_ERROR   = 'SET_CUSTOMER_WHOLEGOODS_ERROR';
export const SET_COMMENTS                    = 'SET_COMMENTS';
export const SET_COMMENTS_LOADING            = 'SET_COMMENTS_LOADING';
export const SET_COMMENTS_ERROR              = 'SET_COMMENTS_ERROR';
export const UPDATE_CHECKLIST                = 'UPDATE_CHECKLIST';
export const SET_CHECKLISTS_LOADING          = 'SET_CHECKLISTS_LOADING';
export const SET_CHECKLISTS_ERROR            = 'SET_CHECKLISTS_ERROR';
export const CLEAR_JOB                       = 'CLEAR_JOB';
export const CLEAR_JOB_ERROR                 = 'CLEAR_JOB_ERROR';
export const CLEAR_TASKS                     = 'CLEAR_TASKS';
export const CLEAR_TASKS_ERROR               = 'CLEAR_TASKS_ERROR';
export const CLEAR_ACTIVITIES                = 'CLEAR_ACTIVITIES';
export const CLEAR_ACTIVITIES_ERROR          = 'CLEAR_ACTIVITIES_ERROR';
export const CLEAR_COMPLETIONS               = 'CLEAR_COMPLETIONS';
export const CLEAR_COMPLETIONS_ERROR         = 'CLEAR_COMPLETIONS_ERROR';
export const CLEAR_ACTIVE_JOBS               = 'CLEAR_ACTIVE_JOBS';
export const CLEAR_ACTIVE_JOBS_ERROR         = 'CLEAR_ACTIVE_JOBS_ERROR';
export const CLEAR_LOGGED_JOBS               = 'CLEAR_LOGGED_JOBS';
export const CLEAR_LOGGED_JOBS_ERROR         = 'CLEAR_LOGGED_JOBS_ERROR';
export const CLEAR_JOBS_KANBAN_SEARCH        = 'CLEAR_JOBS_KANBAN_SEARCH';
export const CLEAR_JOB_SUB_STATES            = 'CLEAR_JOB_SUB_STATES';
export const CLEAR_JOB_SUB_STATES_ERROR      = 'CLEAR_JOB_SUB_STATES_ERROR';
export const CLEAR_CHECKLISTS                = 'CLEAR_CHECKLISTS';
export const CLEAR_CHECKLISTS_ERROR          = 'CLEAR_CHECKLISTS_ERROR';
export const CLEAR_MILEAGES                  = 'CLEAR_MILEAGES';
export const CLEAR_MILEAGES_ERROR            = 'CLEAR_MILEAGES_ERROR';
export const CLEAR_NOTES                     = 'CLEAR_NOTES';
export const CLEAR_NOTES_ERROR               = 'CLEAR_NOTES_ERROR';
export const CLEAR_SERIAL_NUMBER             = 'CLEAR_SERIAL_NUMBER';
export const CLEAR_SERIAL_NUMBER_ERROR       = 'CLEAR_SERIAL_NUMBER_ERROR';
export const CLEAR_SIGNATURES                = 'CLEAR_SIGNATURES';
export const CLEAR_SIGNATURES_ERROR          = 'CLEAR_SIGNATURES_ERROR';
export const CLEAR_CLOCK_HOURS               = 'CLEAR_CLOCK_HOURS';
export const CLEAR_CLOCK_HOURS_ERROR         = 'CLEAR_CLOCK_HOURS_ERROR';
export const CLEAR_ENGINEERS                 = 'CLEAR_ENGINEERS';
export const CLEAR_ENGINEERS_ERROR           = 'CLEAR_ENGINEERS_ERROR';
export const CLEAR_CUSTOMER_WHOLEGOODS       = 'CLEAR_CUSTOMER_WHOLEGOODS';
export const CLEAR_CUSTOMER_WHOLEGOODS_ERROR = 'CLEAR_CUSTOMER_WHOLEGOODS_ERROR';
export const CLEAR_COMMENTS                  = 'CLEAR_COMMENTS';
export const CLEAR_COMMENTS_ERROR            = 'CLEAR_COMMENTS_ERROR';
