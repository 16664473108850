<template>
    <div class="customer-lookup">
        <!--- Input -->
        <div class="input-group"
            :class="{ 'is-invalid': !item.valid}">
            <b-form-input v-model="item.display"
                          :state="item.valid"
                          @keydown.enter.native="getCustomer"
                          @blur.native="getCustomer"
                          @keydown.question.native="openModal"
                          @focus.native="$event.target.select()"/>
            <div class="input-group-append">
                <button type="button"
                        class="btn ib-append-button"
                        tabindex="-1"
                        @click="openModal">
                        <div v-show="!item.searching">
                            <i class="fas fa-search"/>
                        </div>
                        <div v-show="item.searching">
                            <i class="fas fa-sync-alt fa-spin"/>
                        </div>
                </button>
            </div>
        </div>
        <div class="invalid-feedback">
            {{ invalidMessage }}
        </div>
        <!-- Modal -->
        <b-modal v-model="showModal"
                 class="lookup-modal"
                 size="lg"
                 title="Customer Lookup"
                 @ok="handleOk"
                 @hidden="clear"
                 @shown="focusInput">
            <!-- Search Box -->
            <div class="search">
                <div class="input-group">
                    <input ref="search"
                           type="text"
                           class="form-control"
                           placeholder="Enter Search Text"
                           v-model="filter">
                    <div class="input-group-append">
                        <button type="button"
                                class="btn btn-secondary rounded-right-borders"
                                :disabled="!filter"
                                @click="clear">Clear</button>
                    </div>
                </div>
            </div>
            <!-- Results -->
            <div class="table">
                <ib-table :items="table.items"
                          :columns="table.columns"
                          :notItemsMessage="messages.noResult"
                          :itemsPerPage="10"
                          :small="true"
                          :loading="table.searching"
                          :loadingMessage="messages.searching"
                          v-model="table.selectedItem"/>
            </div>
        </b-modal>
                 
    </div>
</template>
<script>
import CustomerApi  from '@WS/api/customer';
import IbLookupBase from '@/components/form/IbLookupBase';
import IbTable      from '@/components/table/IbTable';
const TABLE_COLUMNS = [
    {
        heading:  'Account',
        property: 'AccountNo',
        sortable: true,
        class:    'accountCol'
    },
    { 
        heading:  'Name',
        property: 'Name',
        sortable: true,
        class:    'nameCol'
    },
    { 
        heading:  'NickName',
        property: 'NickName',
        sortable: true,
        class:    'nickNameCol'
    },
    {
        heading:  'Address',
        property: 'Address1',
        sortable: true,
        class:    'addressCol'
    }
];
const FILTERED_PROPERTIES = [
    'AccountNo',
    'Name',
    'Address1'
];
export default {
    name: 'IbCustomerLookup',
    extends: IbLookupBase,
    components: {
        IbTable
    },
    data(){
        return {
            invalidMessage: "Invalid customer"
        }
    },
    props: {
        value: {
            required: true,
            default:  null
        },
        minAccountNo: null,
        maxAccountNo: null
    },
    mounted() {        
        this.table.columns          = TABLE_COLUMNS;
        this.table.filterProperties = FILTERED_PROPERTIES;
    },
    methods: {
        /**
         * Get customer using term entered in input. Assumes customer nickname or
         * ID is entered. Using all entered up to the first spacef if there is one.
         */
        async getCustomer() {
            this.item.searching = true;
            let term = this.item.display;
            if (!term) {                
                //No search term - fail fast.
                this.item.searching = false;
                this.item.valid = false;
                return;
            }
            // if there is a space in the string, take everything from the start to the space
            if (-1 != term.indexOf(' ')) {
                term = term.substr(0, term.indexOf(' '));
            }
            try {
                const searchResponse = await CustomerApi.searchCustomerByNickname(term);
                
                if (true === searchResponse.data.success) {
                    this.item.value = _.head(searchResponse.data.data);
                }
            }
            catch (error) { 
                this.item.valid = false;
            }
            finally {
                this.item.searching = false;                
            }
        },
        /**
         * Search customers by the filter provided.
         */
        async search(filter) {
            this.table.searching    = true;
            this.table.items        = [];
            this.table.selectedItem = null;
            this.table.error        = null;
            try {
                let searchResponse = {}
                if (this.minAccountNo && this.maxAccountNo) {
                    searchResponse = await CustomerApi.searchCustomersInRange(filter, this.minAccountNo, this.maxAccountNo);
                }
                else {
                    searchResponse = await CustomerApi.searchCustomers(filter);
                }

                this.table.items = searchResponse.data.data;
            }
            catch (error) {
                this.table.error = error.message;
            }
            finally {
                this.table.searching = false;
            }
        },
        /**
         * Get the formatted customer name. Returning empty string
         * if no customer.
         */
        getCustomerName(customer) {
            let name = null;
            if (false === _.isEmpty(customer)) {
                name = `${customer.AccountNo} - ${customer.Name}`;
            }
            return name;
        },

        /**
         * Validate that the customer account is...
         * - set
         * - between min and max account numbers (if either is set.)
         */
        validateCustomerAccount(){         
            let accountValid = false; // Account number exists.
            let fromValid    = true; // Account number is greater than minium rnage if set.
            let toValid      = true;
            let account      = _.get(this.item.value, 'AccountNo');

            accountValid = !_.isEmpty(account);
            if (accountValid){
                account = account.padStart(6, ' ');
            }

            if (!_.isEmpty(this.minAccountNo)) {                
                fromValid = account >= this.minAccountNo.padStart(6, ' ')
            }

            if (!_.isEmpty(this.maxAccountNo)) {                
                toValid = account <= this.maxAccountNo.padStart(6, ' ')
            }

            this.item.valid = accountValid && fromValid && toValid;

            if (false === this.item.valid){
                // Build an appropiate error message.
                let message = "Invalid customer account";
                if (true == accountValid && (!fromValid || !toValid) ) {
                    message = `Customer account must be between ${this.minAccountNo||0} and ${this.maxAccountNo|| 999999}`
                }

                this.invalidMessage = message;
            }
        }
    },
    watch: {
        value(newValue) {
            this.item.value = newValue;
        },
        "item.value": function(newValue) {
            this.item.display = this.getCustomerName(this.item.value);
            this.validateCustomerAccount();
            this.$emit('input', this.item.value);
        },

        "item.valid": function(newValue) {
            this.$emit('valid', this.item.valid);
        },

        minAccountNo(newValue, oldValue){
            if (newValue && newValue != oldValue){
                this.validateCustomerAccount();
            }            
        },

        maxAccountNo(newValue, oldValue){
            if (newValue && newValue != oldValue){
                this.validateCustomerAccount();
            }            
        }
    }
}
</script>
<style lang="less" scoped>
.error {
    color: red;
    visibility: hidden;
}
.show-error {
    visibility: visible !important;
}
.lookup-modal {
    .search {
        min-height: 60px;
    }
    .table {
        min-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
/deep/ .accountCol {
    width: 90px;
}
/deep/ .nameCol {
    min-width: 100px;
}
/deep/ .addressCol {
    min-width: 150px;
}
/deep/ .nickNameCol {
    min-width: 150px;
}
</style>