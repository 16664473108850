<template>
    <div>
        <ib-warning-modal v-model="showWarning" @hideWarning="hideWarning" 
        :message="`These settings change the behaviour of the Workshop Job Request System and should not be modified by unauthorised users.`"/>

        <h4>Job Requests Configuration</h4>
        <hr>
        <div v-if="loading" style="overflow-y: auto; height:calc(100vh - 150px);" class="text-center">
            <ib-loading-spinner :message="`Loading...`" />
           
        </div>
        <div v-else style="overflow-y: auto; height:calc(100vh - 150px);">
            <div>
                <h6>Invoice Types to Include in iMHC Notifications:</h6>
                <a href="#" @click="selectAllInvoiceTypes">Select All</a> / <a href="#" @click="selectNoneInvoiceTypes">Select None</a>
            </div>
            <div class="boxwide">
                    <div v-for="(invoiceType,invoiceTypeIndex) in invoiceTypes" class="p-2" :key="invoiceTypeIndex">
                    <input type="checkbox" name ="invoiceType" :id="invoiceType.Type" v-model="invoiceType.selected" />&nbsp;{{invoiceType.Type}}
                    <br><span class="text-muted description-text">{{invoiceType.Description}}</span>
                </div>
            </div>
            <hr>

            <div>
                <h6>Make Codes to Include in iMHC Notifications:</h6>
                <a href="#" @click="selectAllMakeCodes">Select All</a> / <a href="#" @click="selectNoneMakeCodes">Select None</a>
            </div>
            <div class="boxwide">
                    <div v-for="(makeCode,makeCodeIndex) in makeCodes" class="p-2" :key="makeCodeIndex">
                    <input type="checkbox" name ="makeCode" :id="makeCode.Prefix" v-model="makeCode.selected" />&nbsp;{{makeCode.Prefix}}
                    <br><span class="text-muted description-text">{{makeCode.Desc}}</span>
                </div>
            </div>
            <hr>

            <div>
                <h6>Part Prefixes to Include in iMHC Data Feed:</h6>
                <a href="#" @click="selectAllPrefixes">Select All</a> / <a href="#" @click="selectNonePrefixes">Select None</a>
            </div>
            <div class="boxwide">
                    <div v-for="(prefix,prefixIndex) in partPrefixes" class="p-2" :key="prefixIndex">
                    <input type="checkbox" name ="prefix" :id="prefix.Prefix" v-model="prefix.selected" />&nbsp;{{prefix.Prefix}}
                    <br><span class="text-muted description-text">{{prefix.Desc}}</span>
                </div>
            </div>
        </div>
        <hr>

        <!-- buttons -->
        <div class="float-right">
            <button type="button" class="btn btn-secondary" @click="loadSettings">Revert</button>&nbsp;
            <button type="button" class="btn btn-success" @click="saveSettings">Ok</button>
        </div>

    </div>
</template>
<script>
import InvoiceTypeApi       from '@WS/api/invoice-type';
import PartsApi             from '@WS/api/parts';
import JobApi               from '@WS/api/job'
import IbWarningModal       from '@/components/IbWarningModal';
import ToastMixin, { ToastVariant, ToastTarget } from '@/mixins/ToastMixin';
import IbLoadingSpinner     from '@/components/IbLoadingSpinner';

export default {
    name : 'jobrequestconfiguration',

    components: {
        IbLoadingSpinner,
        IbWarningModal
    },

    mixins: [
        ToastMixin
    ],

    data () {
        return {
            invoiceTypes    : [],
            makeCodes       : [],
            partPrefixes    : [],
            loading         : false,
            showWarning     : false 
        }
    },
    async created () {
        this.loading = true;
        this.showWarning = true;
        this.getInvoiceTypes();
        this.getMakeCodes();
        this.getPrefixes();
        this.loadSettings();
    },
    methods : {
        hideWarning() {
            this.showWarning = false;
        },

        async getInvoiceTypes() {
            const invalidProgCodes = [2, 4, 9, -3, -15, -16, -19, -20, -23, -24];

            let invoiceTypes = await InvoiceTypeApi.getInvoiceTypeNames();
            invoiceTypes.data.data = invoiceTypes.data.data.map(invoiceType => ({...invoiceType,selected:false}));
            invoiceTypes.data.data = invoiceTypes.data.data.filter(item => {
                let result = true;
                // return false if item is in invalid prog codes
                if(invalidProgCodes.includes(item.ProgCode)) {
                    result = false;
                }
                return result;
            });
            this.invoiceTypes = invoiceTypes.data.data;
        },
        async getMakeCodes() {
            let prefixes = await PartsApi.getPrefixes();
            this.makeCodes = prefixes.data.data.map(prefix => ({...prefix,selected:false}));
        },
        async getPrefixes() {
            let prefixes = await PartsApi.getPrefixes();
            this.partPrefixes = prefixes.data.data.map(prefix => ({...prefix,selected:false}));
        },


        selectAllInvoiceTypes() {
            this.invoiceTypes = this.invoiceTypes.map(invoiceType => ({...invoiceType,selected:true}));
        },
        selectNoneInvoiceTypes() {
            this.invoiceTypes = this.invoiceTypes.map(invoiceType => ({...invoiceType,selected:false}));
        },
        selectAllMakeCodes() {
            this.makeCodes = this.makeCodes.map(makeCode => ({...makeCode,selected:true}));
        },
        selectNoneMakeCodes() {
            this.makeCodes = this.makeCodes.map(makeCode => ({...makeCode,selected:false}));
        },
        selectAllPrefixes() {
            this.partPrefixes = this.partPrefixes.map(prefix => ({...prefix,selected:true}));
        },
        selectNonePrefixes() {
            this.partPrefixes = this.partPrefixes.map(prefix => ({...prefix,selected:false}));
        },


        async loadSettings() {
            this.loading = true;
            let configuration = await JobApi.getJobRequestsConfiguration();
            let iMhcInvoiceTypes         = configuration.data.data.ImhcNotificationInvoiceTypes.split(',');
            let iMhcMakeCodes            = configuration.data.data.ImhcNotificationMakeCodes.split(',');
            let imhcDataFeedPartPrefixes = configuration.data.data.ImhcDataFeedPartPrefixes.split(',');

            let invoiceTypes = this.invoiceTypes;
            invoiceTypes = invoiceTypes.map(invoiceType => ({...invoiceType,selected:iMhcInvoiceTypes.includes(invoiceType.Type)}));
            this.invoiceTypes = invoiceTypes;


            let makeCodes = this.makeCodes;
            makeCodes = makeCodes.map(makeCode => ({...makeCode,selected:iMhcMakeCodes.includes(makeCode.Prefix)}));
            this.makeCodes = makeCodes;


            let partPrefixes = this.partPrefixes;
            partPrefixes = partPrefixes.map(partPrefix => ({...partPrefix,selected:imhcDataFeedPartPrefixes.includes(partPrefix.Prefix)}));
            this.partPrefixes = partPrefixes;
            this.loading = false;
        },
        async saveSettings() {
            let selectedInvoiceTypes = this.invoiceTypes.filter(function(i) {
                    return i.selected === true;
                });
            selectedInvoiceTypes = selectedInvoiceTypes.map(i => i.Type);

            let selectedMakeCodes = this.makeCodes.filter(function(m) {
                    return m.selected === true;
                });
            selectedMakeCodes = selectedMakeCodes.map(i => i.Prefix);

            let selectedPartPrefixes = this.partPrefixes.filter(function(p) {
                    return p.selected === true;
                });
            selectedPartPrefixes = selectedPartPrefixes.map(p => p.Prefix);

            let settings = {
                'ImhcNotificationInvoiceTypes' : selectedInvoiceTypes.join(), 
                'ImhcNotificationMakeCodes'    : selectedMakeCodes.join(), 
                'ImhcDataFeedPartPrefixes'     : selectedPartPrefixes.join()
            };
            
            try
            {
                await JobApi.setJobRequestsConfiguration(settings);
                this.toast('Job Requests Configuration',
                            ToastTarget.TOP_CENTER,
                            ToastVariant.SUCCESS,
                            'Configuration saved successfully');
            }
            catch
            {
                this.toast('Job Requests Configuration',
                            ToastTarget.TOP_CENTER,
                            ToastVariant.DANGER,
                            'Unable to save configuration');        
            }
        }
    },
}
</script>

<style scoped>
    .boxwide {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    }
    .description-text {
        font-size: 12px;
    }
</style></style>
